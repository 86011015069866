(function () {
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const sliderWrappers = [...document.querySelectorAll('.slider-wrapper')];
  const stepsElements = [...document.querySelectorAll('.steps')];
  const sliderMargin = 24;
  const sliderGap = 8;

  stepsElements.map((element) => element.classList.add(isMobile ? 'mobile' : 'desktop'))

  sliderWrappers.map((slider) => {
    slider.classList.add(isMobile ? 'mobile' : 'desktop');

    if (isMobile) {
      const sliderItems = [...slider.querySelectorAll('[data-slider-item]')];
      const sliderItemWidth = slider.clientWidth - 2 * sliderMargin;
      const width = sliderItems.length * sliderItemWidth + (sliderItems.length - 1) * sliderGap
      const sliderTrack = slider.querySelector('.slider-track');
      let startX = 0;
      let currentTranslate = sliderMargin;

      sliderTrack.style.width = `${width}px`
      sliderTrack.style.transform = `translateX(${sliderMargin}px)`;
      sliderItems.map((item) => item.style.width = `${sliderItemWidth}px`)

      sliderTrack.ontouchstart = (e) => {
        startX = e.touches[0].pageX;
        currentTranslate = +sliderTrack.style.transform.replace(/[^-?\d.]/g, '');

        sliderTrack.classList.add('moving');
      };

      sliderTrack.ontouchend = () => {
        const transformValue = +sliderTrack.style.transform.replace(/[^-?\d.]/g, '');
        const isLeftMoved = currentTranslate >= transformValue;

        sliderTrack.classList.remove('moving');

        for (let idx = 0; idx < sliderItems.length; idx += 1) {
          const leftPartOfElement = -sliderItemWidth * (idx + 0.25) + sliderMargin;
          const rightPartOfElement = -sliderItemWidth * (idx + 0.75) + sliderMargin;
          const offset = sliderItems[idx].offsetLeft;
          const withStepsBlock = slider.previousElementSibling.classList.contains('steps');

          if (rightPartOfElement >= transformValue && !isLeftMoved) {
            continue;
          }

          if (leftPartOfElement >= transformValue && isLeftMoved) {
            continue;
          }

          sliderTrack.style.transform = `translateX(${-offset + sliderMargin}px)`;

          if (withStepsBlock) {
            slider.previousElementSibling.querySelector('.active[data-slider-step]').classList.remove('active')
            slider.previousElementSibling.querySelector(`[data-slider-step=${sliderItems[idx].dataset.sliderItem}]`).classList.add('active');
          }

          break;
        }
      };

      sliderTrack.ontouchmove = (e) => {
        const walk = e.touches[0].pageX - startX;
        const updatedTranslate = currentTranslate + walk;
        const outOfRange = updatedTranslate > -sliderItems[0].offsetLeft + 2 * sliderMargin ||
          updatedTranslate < -sliderItems[sliderItems.length - 1].offsetLeft

        if (outOfRange) {
          return;
        }

        sliderTrack.style.transform = `translateX(${updatedTranslate}px)`;
      };
    }
  });

  const stepsItems = [...document.querySelectorAll('.steps div')];
  const onStepClick = ({ currentTarget }) => {
    if (currentTarget.classList.contains('active')) {
      return;
    }

    const {
      dataset: { sliderStep },
      parentElement
    } = currentTarget;

    parentElement.querySelector('.active').classList.remove('active');
    parentElement.nextElementSibling.querySelector('.active').classList.remove('active');
    parentElement.nextElementSibling.querySelector(`[data-slider-item=${sliderStep}]`).classList.add('active');
    currentTarget.classList.add('active');

    if (isMobile) {
      const sliderItems = [...document.querySelectorAll('.slider-track [data-slider-item]')]
      const sliderItemIndex = sliderItems.findIndex((item) => item.dataset.sliderItem === sliderStep)
      const offset = sliderItems[sliderItemIndex].offsetLeft
      parentElement.nextElementSibling.querySelector('.slider-track').style.transform = `translateX(${-offset + sliderMargin}px)`;
    }
  }
 
  stepsItems.map((item) => {
    item.onclick = onStepClick;
  })
})();
